function getEntityOptions(selectedOptions) {
    console.log(selectedOptions);
    var fullOptions = [];
    // If there is no selected options, return nothing.
    if (selectedOptions.length == 0) {
        console.log('went here')
        return fullOptions;
    }
    for (var i = 0; i < selectedOptions.length; i++) {
        var option = selectedOptions[i];
        if (option == 'PII') {
            var options1 = [
                'DATE_TIME',
                'EMAIL_ADDRESS',
                'IP_ADDRESS',
                'NRP',
                'LOCATION',
                'PERSON',
                'PHONE_NUMBER',
                'URL',
                'US_DRIVER_LICENSE',
                'US_PASSPORT',
                'US_SSN',
                'UK_NHS',
                // 'NIF',
                // 'IT_FISCAL_CODE',
                // 'IT_DRIVER_LICENSE',
                // 'IT_VAT_CODE',
                // 'IT_PASSPORT',
                // 'IT_IDENTITY_CARD',
                'AU_ABN',
                'AU_ACN',
                'STATE',
                'SPELLING'
            ];
            fullOptions = fullOptions.concat(options1);
        } else if (option == 'PCI') {
            var options2 = [
                'CREDIT_CARD',
                'IBAN_CODE',
                'US_BANK_NUMBER',
                'US_ITIN',
                'AU_TFN',
                'CC_PARTIAL'
            ];
            fullOptions = fullOptions.concat(options2);
        } else if (option == 'PHI') {
            var options3 = [
                'MEDICAL_LICENSE',
                'AU_MEDICARE',
                "ACTIVITY",
                "ADMINISTRATION",
                "AGE",
                "AREA",
                "BIOLOGICAL_ATTRIBUTE",
                "BIOLOGICAL_STRUCTURE",
                "CLINICAL_EVENT",
                "COLOR",
                "COREFERENCE",
                "DATE",
                "DETAILED_DESCRIPTION",
                "DIAGNOSTIC_PROCEDURE",
                "DISEASE_DISORDER",
                "DISTANCE",
                "DOSAGE",
                "DURATION",
                "FAMILY_HISTORY",
                "FREQUENCY",
                "HEIGHT",
                "HISTORY",
                "LAB_VALUE",
                "MASS",
                "MEDICATION",
                "NON[BIOLOGICAL](DETAILED_DESCRIPTION",
                "NONBIOLOGICAL_LOCATION",
                "OCCUPATION",
                "OTHER_ENTITY",
                "OTHER_EVENT",
                "OUTCOME",
                "PERSONAL_[BACK](BIOLOGICAL_STRUCTURE",
                "PERSONAL_BACKGROUND",
                "QUALITATIVE_CONCEPT",
                "QUANITATIVE_CONCEPT",
                "SEVERITY",
                "SEX",
                "SHAPE",
                "SIGN_SYMPTOM",
                "SUBJECT",
                "TEXTURE",
                "THERAPEUTIC_PROCEDURE",
                "TIME",
                "VOLUME",
                "WEIGHT"
            ];
            fullOptions = fullOptions.concat(options3);
        } else if (option == 'OTHER') {
            var options4 = ['CRYPTO'];
            fullOptions = fullOptions.concat(options4);
        }
    }
    return fullOptions;
}
export {
    getEntityOptions
}
