<template>
  <div id="app">
    <div class="logo-banner">
      <span><img src="img/ontelio_logo.png" class="logo-img" /></span>
    </div>
    <div class="file-uploader">
      <span>
        <audio
          id="audio-player"
          controls
          v-if="message == 'Completed' && redactedMedia != null"
        >
          <source :src="redactedMedia" type="audio/mpeg" />
          Your browser does not support the audio tag.
        </audio>
      </span>
      <BlockUI :blocked="blocked">
        <div class="entityOptionsDiv">
          <span>
            <span class="optionsTitle">Redaction Options: </span>
            <span v-for="entity in entityOptions" :key="entity.name">
              <label :for="entity.name">{{ entity.name }}</label>
              <input
                type="checkbox"
                :id="entity.name"
                :value="entity.value"
                v-model="entitiesChosen"
              />
            </span>
          </span>
          <span class="leftDivider">
            <span class="optionsTitle">Enable/Disable Options: </span>
            <span>
              <label>Media Redaction</label>
              <input
                type="checkbox"
                id="mediaRedactionEnabled"
                :value="enableMediaRedaction"
                v-model="enableMediaRedaction"
              />
            </span>
          </span>
          <span class="leftDivider">
            <span class="optionsTitle">Language: </span>
            <span>
              <Dropdown
                id="mediaLanguage"
                :options="languageOptions"
                optionLabel="name"
                v-model="selectedLanguage"
              />
            </span>
          </span>
        </div>
        <FileUpload
          name="demo[]"
          customUpload
          @uploader="onUpload"
          :multiple="false"
          :auto="false"
          accept="audio/wav"
          :maxFileSize="50000000"
        >
          <template #empty>
            <p>Drag and drop a .wav file to here to upload.</p>
          </template>
        </FileUpload>
      </BlockUI>
      <ProgressBar :value="value">{{ message }}</ProgressBar>
      <div class="messageArea">
        <Message severity="error" v-if="error">{{ error }}</Message>
      </div>
      <div style="width: 100%; display: table" v-if="message == 'Completed'">
        <div style="display: table-row; height: 100px">
          <div style="width: 50%; display: table-cell">
            <DataTable
              :value="redactedTranscript"
              :rowStyle="rowStyle"
              rowGroupMode="rowspan"
              showGridlines
              stripedRows
              :class="`p-datatable-sm`"
              tableStyle="min-width: 25rem"
            >
              <template #header>
                <div
                  class="flex flex-wrap align-items-center justify-content-between gap-2"
                >
                  <span class="text-xl text-900 font-bold"
                    >Redacted Transcript</span
                  >
                </div>
              </template>
              <Column field="start" header="Start"></Column>
              <Column field="end" header="End"></Column>
              <Column field="text" header="Text">
                <template #body="slotProps">
                  <span :innerHTML="slotProps.data.text"></span>
                </template>
              </Column>
            </DataTable>
          </div>
          <!-- <div style="display: table-cell; border-left: 2px solid black;">
            <DataTable :value="originalTranscript" :rowStyle="rowStyle" rowGroupMode="rowspan"
              showGridlines stripedRows :class="`p-datatable-sm`" tableStyle="min-width: 25rem">
              <template #header>
                <div class="flex flex-wrap align-items-center justify-content-between gap-2">
                  <span class="text-xl text-900 font-bold">Original Transcript</span>
                </div>
              </template>
              <Column field="start" header="Start"></Column>
              <Column field="end" header="End"></Column>
              <Column field="text" header="Text">
                <template #body="slotProps">
                  <span :innerHTML="slotProps.data.text"></span>
                </template>
              </Column>
            </DataTable>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import FileUpload from "primevue/fileupload";
import Message from "primevue/message";
import ProgressBar from "primevue/progressbar";
import BlockUI from "primevue/blockui";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Dropdown from "primevue/dropdown";
import { getTranscriptObject } from "./utility/transcriptFormat";
import { getEntityOptions } from "./utility/entityOptions";

export default {
  name: "App",
  components: {
    FileUpload,
    Message,
    ProgressBar,
    BlockUI,
    DataTable,
    Column,
    Dropdown,
  },
  data() {
    return {
      message: null,
      error: null,
      value: 0,
      job_id: null,
      status: null,
      pollInterval: null,
      blocked: false,
      redactedTranscription: null,
      originalTranscription: null,
      redactedMedia: null,
      entitiesChosen: ["PII", "PCI"],
      entityOptions: [
        { name: "PII", value: "PII" },
        { name: "PCI", value: "PCI" },
        { name: "PHI", value: "PHI" },
      ],
      languageOptions: [
        { name: "English", value: "en" },
        { name: "Spanish", value: "es" },
        { name: "French", value: "fr" },
        { name: "Portuguese", value: "pt" },
      ],
      selectedLanguage: { name: "English", value: "en" },
      enableMediaRedaction: true,
      enableTranscriptRedaction: true,
      audio: null,
      audioText: "Play",
    };
  },
  computed: {
    // Get the transcription from the
    redactedTranscript() {
      return getTranscriptObject(this.redactedTranscription);
    },
    originalTranscript() {
      return getTranscriptObject(this.originalTranscription);
    },
    getFullEntities() {
      return getEntityOptions(this.entitiesChosen);
    },
  },
  methods: {
    getTranscriptObject,
    getEntityOptions,
    async onUpload(event) {
      this.value = 10;
      this.message = null;
      this.error = null;
      this.message = "Sending...";
      this.job_id = null;
      this.blocked = true;
      this.redactedMedia = null;
      this.redactedTranscription = null;
      this.originalTranscription = null;

      // need to show error on uploading more than one file.
      if (event.files.length > 1) {
        this.value = 100;
        this.isLoading = false;
        this.message =
          "Please, upload only one audio file at a time. Try Again";
        this.blocked = false;
      } else {
        this.file = event.files[0];
        console.log(this.file);
        console.log(this.selectedLanguage);

        const formData = new FormData();
        formData.append("file", this.file);
        formData.append(
          "enableTranscriptRedaction",
          this.enableTranscriptRedaction
        );
        formData.append("Language", this.selectedLanguage.value);
        formData.append("enableMediaRedaction", this.enableMediaRedaction);
        // add all the entities chosen.
        this.getFullEntities.forEach(function (value) {
          formData.append("entities[]", value);
        });

        try {
          //Request upload url and job id
          let url = `${process.env.VUE_APP_REDACT_API_URL}/transcribe`;
          let response = await axios.post(
            url,
            {
              filename: this.file.name,
              callback:
                "https://webhook-test.com/db6f16e11cf436ed1351b32fc1ba9890",
              enableMediaRedaction: this.enableMediaRedaction,
              language: this.selectedLanguage.value,
              entities: this.entitiesChosen,
            },
            {
              headers: {
                "x-api-key": process.env.VUE_APP_REDACT_API_KEY,
              },
            }
          );

          this.job_id = response.data.jobId;
          let uploadUrl = response.data.uploadUrl;
          //Upload file for processing
          response = await axios.put(uploadUrl, this.file, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          });
          console.log(response);
          this.message = "Uploaded";
          this.value = 25;
          this.startInterval();
        } catch (error) {
          this.error = error;
          this.message = null;
          this.value = 0;
          this.job_id = null;
          // this.status = null;
          if (this.pollInterval != null) {
            clearInterval(this.pollInterval);
          }
          this.blocked = false;
        }
      }
    },
    startInterval: function () {
      this.pollInterval = setInterval(
        function () {
          try {
            const url = `${process.env.VUE_APP_REDACT_API_URL}/job/`;
            axios.defaults.headers["x-api-key"] =
              process.env.VUE_APP_REDACT_API_KEY;
            //this.job_id
            axios
              .get(url + this.job_id, {
                validateStatus: function (status) {
                  return status < 500; // Resolve only if the status code is less than 500
                },
              })
              .then((response) => {
                // bad request.
                if (response.status == 400) {
                  this.blocked = false;
                  // this.status = 'Error';
                  this.message = "Error!";
                  this.value = 100;
                  clearInterval(this.pollInterval);
                  this.error =
                    "Error on one of the stages of the pipeline. Please try again later.";
                }
                // get the status
                var s = response.data.status;
                if (s == null) {
                  clearInterval(this.pollInterval);
                  this.value = 100;
                  this.message = "Completed";

                  //this.originalTranscription = JSON.parse(response.data.transcript);
                  this.redactedTranscription = response.data.transcript;
                  console.log(response.data);
                  this.redactedMedia = response.data.mediaUrl;
                  this.blocked = false;
                } else {
                  var sCamel = s.replace(/\w+/g, function (w) {
                    return w[0].toUpperCase() + w.slice(1).toLowerCase();
                  });
                  //this.status = sCamel;
                  this.message = sCamel;
                  if (s == "UPLOADED") {
                    this.value = 30;
                  } else if (s == "TRANSCRIBING") {
                    this.value = 40;
                  } else if (s == "STARTED TRANSCRIPTION REDACTION") {
                    this.value = 50;
                  } else if (s == "FINISHED TRANSCRIPTION REDACTION") {
                    this.value = 60;
                  } else if (s == "STARTED MEDIA REDACTION") {
                    this.value = 85;
                  } else if (s == "ERROR") {
                    this.value = 100;
                    clearInterval(this.pollInterval);
                    this.originalTranscription = null;
                    this.redactedTranscription = null;
                    this.redactedMedia = null;
                    this.blocked = false;
                  }
                }
              })
              .catch(() => {
                this.blocked = false;
                this.status = "Error - 100%";
                this.value = 100;
                clearInterval(this.pollInterval);
              });
          } catch (error) {
            clearInterval(this.pollInterval);
            this.error = error;
            this.message = null;
            this.value = 0;
            this.blocked = false;
          }
        }.bind(this),
        1000
      );
    },
    rowStyle(data) {
      if (data.channel === 0) {
        return {
          "background-color": "white",
          "font-size": "0.85rem",
        };
      } else {
        return {
          "background-color": "#F5F5F5",
          "font-size": "0.85rem",
        };
      }
    },
  },
};
</script>

<style>
@import "primevue/resources/themes/saga-blue/theme.css";
@import "primeicons/primeicons.css";

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  margin-top: 60px;
}

.file-uploader {
  width: 85%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.logo-banner {
  margin-top: 100px;
  margin-bottom: 50px;
  text-align: center;
}

.logo-img {
  height: 35px;
  margin-left: 30px;
  margin-right: 30px;
}

.m-0 {
  font-size: 13px;
  text-align: left;
}

.p-datatable-header {
  font-size: 11px;
  background-color: #113768;
  color: white;
}

.p-progressbar .p-progressbar-value {
  background-color: #0598ce;
}

span.p-column-title {
  font-size: 11px;
}

.entityOptionsDiv {
  background-color: #efefef;
  width: 100%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  border-radius: 4px;
  border: 1px solid #dee2e6;
  border-bottom: 2px solid #dee2e6;
  padding: 1rem 1.25rem;
}

.entityOptionsDiv span {
  margin-right: 0.5rem;
  width: 100px;
  display: inline;
}

.optionsTitle {
  font-weight: bold;
}

.leftDivider {
  border-left: 2px solid black;
  padding-left: 1rem;
}

.p-hidden-accessible {
  display: none !important;
}
</style>
