function getTranscriptObject(transcript) {
    console.log(transcript)
    if (transcript == null) {
        return null
    }
    var t = transcript;
    var segments = [];
    for (var i = 0; i < t.segments.length; i++) {
        var text = "";
        var start = "";
        var end = "";
        start = t.segments[i].start;
        end = t.segments[i].end;
        for (var j = 0; j < t.segments[i].words.length; j++) {
            const regex = new RegExp(/\[(\w+)\]/);
            var word = t.segments[i].words[j].text;
            if (regex.test(word) == true) {
                text += '<b>' + word + '</b>';
            } else {
                text += word + " ";
            }
        }
        var segment = {"start": start, "end": end, "text": text}
        segments.push(segment);
    }
    return segments;
}

export { getTranscriptObject }
